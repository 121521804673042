import * as React from "react"
import styled from 'styled-components'
import { graphql, Link } from 'gatsby'
import { Helmet } from 'react-helmet'
import { BlockRenderer, Layout, Picture, Video, Introduction } from '@components'
import { H1, Paragraph } from '../components/BlockRenderer'
import { palette, typeface } from '@theme'


interface PageProps {
  data: {
    sanityPage: GatsbyTypes.SanityPage
  }
}


const AboutPage = ({ data }: PageProps) => {
  const { sanityPage: { _rawDescription, pressLinks, talks }} = data
  return (
    <>
      <Helmet>
        <meta name="theme-color" content={palette.rawUmber} />
      </Helmet>
      <Layout background={palette.rawUmber} >
        <StyledIntroduction>
          <BlockRenderer>{_rawDescription}</BlockRenderer>
        </StyledIntroduction>
        <Section>
          <Talks>
            {talks?.map(talk => !!talk && (
              <Talk key={talk._key} >
                <Media>
                  {!talk.mediaType && talk.image && talk.link && (
                    <a href={talk.link} target="_blank" >
                      <Picture src={talk.image!.asset?.url!} />
                    </a>
                  )}
                  {talk.mediaType && talk.video && (
                    <Video autoPlay muted loop playsInline src={talk.video!.asset?.url!} />
                  )}
                </Media>
                <Copy>
                  {talk.link && (
                    <a href={talk.link} target="_blank" >
                      <H1>{talk.title}</H1>
                      <Paragraph>{talk.description}</Paragraph>
                    </a>
                  )}
                  {!talk.link && (
                    <>
                      <H1>{talk.title}</H1>
                      <Paragraph>{talk.description}</Paragraph>
                    </>
                  )}
                </Copy>
              </Talk>
            ))}
          </Talks>
        </Section>
        <Press>
          <H1>Press</H1>
          <ul>
            {pressLinks?.map(pressItem => !!pressItem && (
              <li key={pressItem._key}>
                <div>{pressItem.publication}</div>
                <Link to={pressItem.link!} target="_blank" >{pressItem.title}</Link>
              </li>
            ))}
          </ul>
        </Press>
      </Layout>
    </>
  )
}


const StyledIntroduction = styled(props => <Introduction {...props} />)`
  @media only screen and (min-width: 744px) {
    max-width: 75vw;
    margin: 0 4vw;
  }
  @media only screen and (min-width: 2000px) {
    max-width: 1500px;
    margin: 0 80px;
  }
  background-color: ${palette.rawUmber};
  color: ${palette.cosmos};
  a {
    font-family: ${typeface.sans}, sans;
    font-weight: 900;
    text-decoration: none;
    color: ${palette.white};
    &:hover {
      color: ${palette.lime};
    }
  }
`


const Talks = styled.main`
  display: grid;
  grid-column-gap: 10px;
  grid-row-gap: 25px;
  grid-template-columns: 1;
  @media only screen and (min-width: 744px) {
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 20px;
    grid-row-gap: 50px;
  }
  color: ${palette.white};
`


const Talk = styled.article`
  grid-column: span 1;
  margin-bottom: 1em;
`

const Copy = styled.header`
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-contents: flex-start;
  h1 {
    margin-top: 0.5em;
    margin-bottom: 0.25em;
  }
  p {
    text-align: left;
    color: ${palette.cosmos};
  }
`


const Media = styled.div`
  overflow: hidden;
  img, video {
    border-radius: 10px;
  }
`


const Section = styled.section`
  box-sizing: border-box;
  > * {
    max-width: calc(100vw - 30px);
    margin: auto;
    padding: 8vw 0;
  }
  @media only screen and (min-width: 744px) {
    > * {
      width: 92vw;
      max-width: 2000px;
      padding: 4vw 0;
    }
  }
  @media only screen and (min-width: 2000px) {
    > * {
      max-width: 1840px;
      padding: 4vw 0;
    }
  }
`


const Press = styled(props => <Section {...props} />)`
  background-color: ${palette.cosmos};
  h1 {
    color: ${palette.rawUmber};
    margin-bottom: 0;
  }
  a:hover {
    color: ${palette.white};
  }
  text-transform: uppercase;
  ul {
    padding-top: 0;
    li {
      margin-bottom: 5px;
    }
    div {
      font-family: ${typeface.sans};
      font-weight: 900;
      font-size: 14px;
      letter-spacing: 0px;
      line-height: 1.4em;
    }
    a {
      display: block;
      line-height: 0.9em;
      font-size: 32px;
      letter-spacing: -1px;
    }
    @media only screen and (min-width: 744px) {
      a {
        font-size: 4.8vw;
      }
      div {
        font-size: 2vw;
      }
    }
    @media only screen and (min-width: 2000px) {
      a {
        font-size: 96px;
      }
      div {
        font-size: 40px;
      }
    }
  }
`


export const query = graphql`
  query SpeakingPageQuery {
    sanityPage(slug: {current: {eq: "speaking"}}) {
      ...pageFields
    }
  }
`


export default AboutPage
